import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons'
import CreatePostContentInner from './createPostContentInner';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import SEO from "../components/seo";
import {
	setLoggedIn,
	reset,
	setShowSignInModal
} from "../state/actions";
import { FormattedMessage, injectIntl } from 'react-intl';



class CreatePostContent extends React.Component {

	componentDidMount() {
		if (!this.props.loggedIn && !this.props.initialLoader) {
			this.props.setShowSignInModal(true);
		}
	}


	render() {
		if (this.props.loggedIn) {
			return (
				<CreatePostContentInner langUrl={this.props.langUrl} />
			);

		} else {
			return (
				<div className="chatPageWrapper page">
					<SEO
						title={this.props.postTitle}
						description="Skapa inlägg på Dinanyheter"
						pagePath="/createPost"
					/>
					<div className="errorText">Logga in för att skapa inlägg, det tar bara en minut! <br /> <FontAwesomeIcon icon={faHandPointUp} /></div>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	const { loggedIn, initialLoader } = state
	return {
		loggedIn: loggedIn,
		initialLoader: initialLoader
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLoggedIn: setLoggedIn,
		reset: reset,
		setShowSignInModal: setShowSignInModal
	},
		dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(CreatePostContent));

